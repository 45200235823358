const CzFlagImage = () => (
  <svg width="24" height="16" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="nonzero" fill="none">
      <path fill="#D7141A" d="M0 0h24v16H0z" />
      <path fill="#FFF" d="M0 0h24v8H0z" />
      <path fill="#11457E" d="M12 8 0 0v16z" />
    </g>
  </svg>
);

export default CzFlagImage;
