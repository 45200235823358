/* eslint-disable max-len */
import React, { SVGProps } from 'react';

const VisaIcon: React.FC<SVGProps<SVGElement>> = React.memo(props => (
  // @ts-ignore
  <svg {...props} width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" stroke="#EDEAE3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7503 15.8583H8.69051L7.14596 9.79238C7.07265 9.51335 6.91699 9.26667 6.68802 9.15041C6.11659 8.85824 5.48692 8.62571 4.8 8.50844V8.2749H8.11808C8.57603 8.2749 8.91948 8.62571 8.97673 9.03314L9.77813 13.4087L11.8369 8.2749H13.8394L10.7503 15.8583ZM14.9842 15.8583H13.039L14.6408 8.2749H16.586L14.9842 15.8583ZM19.1027 10.3757C19.1599 9.96728 19.5034 9.73375 19.9041 9.73375C20.5338 9.67511 21.2197 9.79238 21.7921 10.0835L22.1356 8.45082C21.5631 8.21728 20.9335 8.10001 20.362 8.10001C18.474 8.10001 17.1002 9.15041 17.1002 10.6082C17.1002 11.7173 18.0733 12.2996 18.7603 12.6504C19.5034 13.0002 19.7896 13.2338 19.7324 13.5836C19.7324 14.1083 19.1599 14.3418 18.5885 14.3418C17.9016 14.3418 17.2147 14.1669 16.586 13.8747L16.2426 15.5085C16.9295 15.7996 17.6726 15.9169 18.3596 15.9169C20.4765 15.9745 21.7921 14.9251 21.7921 13.35C21.7921 11.3665 19.1027 11.2502 19.1027 10.3757ZM28.6 15.8583L27.0554 8.2749H25.3964C25.0529 8.2749 24.7095 8.50844 24.595 8.85824L21.7349 15.8583H23.7374L24.1371 14.7502H26.5975L26.8265 15.8583H28.6ZM25.6826 10.3171L26.254 13.1751H24.6523L25.6826 10.3171Z"
      fill="#172B85"
    />
  </svg>
));

VisaIcon.displayName = 'VisaIcon';
export default VisaIcon;
