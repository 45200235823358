import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { Trans } from '@lingui/react';
import { useMedia } from 'react-use';
import { deviceMaxWidth, deviceMinWidth } from '~/src/styles/breakpoints';
import { Country, Language, routes } from '~/src/utils/routing';
import { getFooterBottomMenuItems, getFooterBottomMenuItemsNonSKCZ, getFooterBottomMenuItemsPL } from './headers/utils';
import LocalizedLink, { useLocalizedRouter } from '~/src/components/LocalizedLink';
import ImageLogoDark from './ImageLogoDark';
import { facebookLink, instagramLink, linkedInLink } from '~/src/utils/socialNetworks';
import FooterLanguageSelector from './FooterLanguageSelector';
import { LI, UL } from './styles';
import FooterLinks from './FooterLinks';
import { openDesktopOrPhoneModal } from '../modal/utils';
import { ModalType } from '../modal/modalTypes';
import LinkedInIcon from '~/src/components/layout/icons/LinkedInIcon';
import MastercardIcon from '~/src/components/layout/icons/MastercardIcon';
import VisaIcon from '~/src/components/layout/icons/VisaIcon';
import ApplePayIcon from '~/src/components/layout/icons/ApplePayIcon';
import GooglePayIcon from '~/src/components/layout/icons/GooglePayIcon';
import SecurityIcon from '~/src/components/layout/icons/SecurityIcon';
import { useTypedSelector } from '~/src/redux/store';
import { settingsSelector } from '~/src/redux/selectors/settings';
import { screenSelector } from '~/src/redux/selectors/screen';
import FoooterFreeway from '~/src/freeway/components/layout/Footer';
import { useCodebase } from 'features/hooks/useCodebase';

const Footer: React.FC = () => {
  const dispatch = useDispatch();
  const settings = useTypedSelector(settingsSelector);
  const screen = useTypedSelector(screenSelector);
  const [isWider, setIsWider] = useState(false);
  const pathNames = ['/search', '/campervan/[carId]'];
  const mobileMediaQuery = useMedia(deviceMaxWidth.phone, false);
  const router = useLocalizedRouter();

  useEffect(() => {
    if (pathNames.includes(router.pathname)) {
      setIsWider(true);
    } else {
      setIsWider(false);
    }
  }, [router.pathname]);

  const isFWC = useCodebase('fwc');

  let finalFooterBottomMenuItems = getFooterBottomMenuItemsNonSKCZ();
  if (settings.language === Language.cs || settings.language === Language.sk)
    finalFooterBottomMenuItems = getFooterBottomMenuItems();
  if (settings.country === Country.pl && settings.language === Language.pl)
    finalFooterBottomMenuItems = getFooterBottomMenuItemsPL();
  const getSocialNetworks = useMemo(
    () => (
      <SocialNetworksWrapper>
        <SocialNetworkLink rel="noreferrer" href={facebookLink[settings.country]} target="_blank">
          <i className="icon-social-fb" />
        </SocialNetworkLink>

        <SocialNetworkLink rel="noreferrer" href={instagramLink[settings.country]} target="_blank">
          <i className="icon-social-instagram" />
        </SocialNetworkLink>

        <SocialNetworkLink rel="noreferrer" href={linkedInLink[settings.country]} target="_blank">
          <LinkedInIcon stroke="var(--color-dark-blue)" />
        </SocialNetworkLink>
      </SocialNetworksWrapper>
    ),
    [settings.country]
  );

  if (isFWC) return <FoooterFreeway />;
  return (
    <>
      <FooterMain id="footer">
        <FooterUL className="r-footer" wider={isWider}>
          <LI className="main">
            <UL className="box">
              <LI className="logo-social">
                <LocalizedLink href={routes.index} passHref={true}>
                  <Logo>
                    <ImageLogoDark />
                  </Logo>
                </LocalizedLink>
                {mobileMediaQuery && getSocialNetworks}
              </LI>
              <LI>
                {!mobileMediaQuery && (
                  <SocialMediaBox>
                    <PaymentInfoBox>
                      <span>
                        <Trans id="followUs.socialMedia" message="Follow us on social media" />
                      </span>
                    </PaymentInfoBox>
                    {getSocialNetworks}
                  </SocialMediaBox>
                )}

                <PaymentInfoBox>
                  <span>
                    <Trans id="mainPage.footer.payPossibilities" message="Bezpečná platba skrze" />
                  </span>
                  <PaymentInfoIcons>
                    <MastercardIcon />
                    <VisaIcon />
                    <ApplePayIcon />
                    <GooglePayIcon />
                  </PaymentInfoIcons>
                </PaymentInfoBox>
                {!mobileMediaQuery && <Divider />}
                <FooterLanguageSelector />
                {mobileMediaQuery && <Divider />}
              </LI>
            </UL>
          </LI>

          <FooterLinks />
        </FooterUL>
        <FooterCopy wider={isWider}>
          <div>
            ©{new Date().getFullYear()}{' '}
            <Trans id="footer.allRightsReserved" message="Campiri.com. Všechna práva vyhrazena." />
          </div>

          <FooterCopyLinks>
            {finalFooterBottomMenuItems.map((item, id) => (
              <FooterCopyLink key={item.label}>
                <LocalizedLink prefetch={false} key={item.route.template} href={item.route} passHref={true}>
                  <a>
                    <Trans id={item.label} />
                  </a>
                </LocalizedLink>
                <DividerHorizontal />
              </FooterCopyLink>
            ))}
            {/* TODO - COOKIES */}
            <FooterCopyLink key="privacy">
              <PrivacyBlock
                onClick={() => {
                  openDesktopOrPhoneModal(ModalType.COOKIES_SETTINGS, screen, dispatch);
                }}
              >
                <SecurityIcon />
                <Trans id="footerLink.privacySettings" message="Nastavení soukromí" />
              </PrivacyBlock>
            </FooterCopyLink>
          </FooterCopyLinks>
        </FooterCopy>
      </FooterMain>
    </>
  );
};

const FooterMain = styled.div`
  position: sticky;
  display: flex;
  flex-flow: row wrap;

  color: black;
  background-color: var(--color-beige);
  flex-direction: column;
  @media ${deviceMinWidth.tablet} {
    padding: 60px;
    padding-bottom: 24px;
  }
  @media ${deviceMaxWidth.tablet} {
    padding: 24px 16px;
  }
`;

const FooterUL = styled(UL)<{ wider?: boolean }>`
  display: flex;
  max-width: ${(props) => (props.wider ? '1240px' : '1040px')};
  margin: 0 auto;
  gap: 32px;

  justify-content: space-between;
  flex-direction: row;
  align-items: baseline;
  padding-bottom: 30px;
  width: 100%;
  border-bottom: 1px solid var(--color-grey);

  @media ${deviceMaxWidth.phone} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Logo = styled.div`
  cursor: pointer;
  margin-right: 32px;
  width: 129px;
  height: 40px;
  @media ${deviceMaxWidth.xs} {
    margin: 0;
  }
`;

const SocialNetworksWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const SocialNetworkLink = styled.a`
  color: var(--color-dark-blue);
  font-size: 1.25em;
  line-height: 1.4;

  &:hover,
  &:focus {
    color: #696b82;

    & svg {
      stroke: #696b82;
    }
  }
`;

const PaymentInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  span {
    font-family: var(--font-poppins);
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    color: var(--color-dark-grey);
  }

  @media ${deviceMaxWidth.phone} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const FooterCopy = styled.div<{ wider?: boolean }>`
  display: flex;
  width: 100%;
  max-width: ${(props) => (props.wider ? '1240px' : '1040px')};
  justify-content: space-between;
  align-items: center;
  margin: 20px auto 0 auto;

  div {
    font-family: var(--font-poppins);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #6f6363;
    /* margin-bottom: 12px; */
  }

  @media ${deviceMaxWidth.phone} {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const FooterCopyLinks = styled.ul`
  display: flex;
  gap: 8px;
  @media ${deviceMaxWidth.phone} {
    flex-direction: row;

    flex-wrap: wrap;
  }

  li {
    position: relative;
    font-family: var(--font-poppins);
    font-size: 11px;

    a {
      font-family: var(--font-poppins);
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: right;
      color: #6f6363;
      cursor: pointer;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
`;

const SocialMediaBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PaymentInfoIcons = styled.div`
  display: flex;
  gap: 8px;

  i {
    height: 24px;
    width: 34px;
  }
`;

const Divider = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 1px;
  background-color: #dad1d1;
`;
const DividerHorizontal = styled.div`
  flex-shrink: 0;
  width: 1px;
  height: 100%;
  background-color: #9c8c8c;
`;
const FooterCopyLink = styled.li`
  display: flex;
  gap: 8px;
  width: max-content;
`;
const PrivacyBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
const mapStateToProps = (state) => ({
  settings: state.settings,
  screen: state.screen,
});

export default connect(mapStateToProps, (dispatch) => ({ dispatch }))(Footer);
