/* eslint-disable max-len */
const EnFlagImage = () => (
  <svg width="24" height="16" viewBox="0 0 24 16" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-29.7%" y="-34.5%" width="159.5%" height="169%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="16" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.121568627 0 0 0 0 0.133333333 0 0 0 0 0.266666667 0 0 0 0.2 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g filter="url(#a)" transform="translate(-16 -160)" fillRule="nonzero" fill="none">
      <path
        d="M17.248 176h21.504c.691-.037 1.248-.592 1.248-1.262v-13.476c0-.691-.587-1.258-1.309-1.262H17.31c-.722.004-1.309.57-1.309 1.262v13.471c0 .675.557 1.23 1.248 1.267z"
        fill="#FEFEFE"
      />
      <path fill="#C8102E" d="M26.322 169.596V176h3.339v-6.404H40v-3.2H29.66V160h-3.338v6.396H16v3.2z" />
      <path
        d="M30.774 165.18V160H38.7a1.324 1.324 0 0 1 1.209.8l-9.135 4.38zM30.774 170.82V176h7.978a1.33 1.33 0 0 0 1.157-.8l-9.135-4.38zM25.209 170.82V176h-7.961a1.32 1.32 0 0 1-1.157-.808l9.118-4.371zM25.209 165.18V160H17.3a1.32 1.32 0 0 0-1.209.808l9.118 4.371zM16 165.333h3.326L16 163.738zM40 165.333h-3.343L40 163.73zM40 170.667h-3.343L40 172.27zM16 170.667h3.326L16 172.262z"
        fill="#012169"
      />
      <path
        fill="#C8102E"
        d="m40 161.354-8.283 3.98h1.853L40 162.25zM24.265 170.667h-1.852L16 173.742v.895l8.283-3.97zM20.574 165.338h1.852L16 162.254v.892zM35.404 170.662h-1.852L40 173.758v-.891z"
      />
    </g>
  </svg>
);

export default EnFlagImage;
