/* eslint-disable max-len */
import React, { SVGProps } from 'react';

const MastercardIcon: React.FC<SVGProps<SVGElement>> = React.memo(props => (
  // @ts-ignore
  <svg {...props} width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" stroke="#EDEAE3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.179 16.8294C15.9949 17.8275 14.459 18.43 12.7807 18.43C9.03582 18.43 6 15.4303 6 11.73C6 8.02966 9.03582 5.02997 12.7807 5.02997C14.459 5.02997 15.9949 5.63247 17.179 6.63051C18.363 5.63247 19.8989 5.02997 21.5773 5.02997C25.3221 5.02997 28.358 8.02966 28.358 11.73C28.358 15.4303 25.3221 18.43 21.5773 18.43C19.8989 18.43 18.363 17.8275 17.179 16.8294Z"
      fill="#ED0006"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.179 16.8294C18.6369 15.6005 19.5614 13.7719 19.5614 11.73C19.5614 9.68801 18.6369 7.85941 17.179 6.63051C18.363 5.63247 19.8989 5.02997 21.5773 5.02997C25.3221 5.02997 28.3579 8.02966 28.3579 11.73C28.3579 15.4303 25.3221 18.43 21.5773 18.43C19.8989 18.43 18.363 17.8275 17.179 16.8294Z"
      fill="#F9A000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.179 16.8294C18.6369 15.6005 19.5614 13.7719 19.5614 11.73C19.5614 9.68803 18.6369 7.85944 17.179 6.63054C15.7211 7.85944 14.7966 9.68803 14.7966 11.73C14.7966 13.7719 15.7211 15.6005 17.179 16.8294Z"
      fill="#FF5E00"
    />
  </svg>
));

MastercardIcon.displayName = 'MastercardIcon';
export default MastercardIcon;
