'use client';

import { Box } from 'ui/primitives/box';
import tokens from 'ui/design/tokens/tokens';
import {
  Flex,
  FlexCenterBoth,
  FlexCol,
  FlexColMdMax,
  FlexJustifySpaceBetween,
  FlexJustifySpaceBetweenAlignCenter,
} from 'ui/primitives/flex';
import FreewayLogo from 'ui/icons/FreeWayLogo.svg';
import FooterMountain from 'ui/icons/FooterMountain.svg';
import styled from 'styled-components';
import { resolveThemeValue } from 'ui/design/utils';
import { Typography } from 'ui/primitives/typography';
import VisaIcon from 'ui/icons/card/visa.svg';
import MastercardIcon from 'ui/icons/card/mastercard.svg';
import TransferIcon from 'ui/icons/card/transfer.svg';
import SepaIcon from 'ui/icons/ic_sepa.svg';
import Przelwy24Icon from 'ui/icons/ic_przelewy24.svg';
import KlarnaIcon from 'ui/icons/ic_klarna.svg';
import PaypalIcon from 'ui/icons/ic_paypal.svg';
import GiroIcon from 'ui/icons/ic_gyropay.svg';
import { Headline } from 'ui/components/primitives/typography/CustomTypography';
import { mediaMdMax, mediaMdMin } from 'ui/utils/breakpoints';
import AccordionFWC from 'ui/components/AccordionFWC';
import ArrowUpIcon from 'ui/icons/ic_arrow_up.svg';
import useBreakpoints, { SMALL_SCREEN_QUERY } from 'features/hooks/useBreakpoints';
import { HiddenOnDesktop, HiddenOnMobile } from 'ui/primitives/hidden';
import useScrollToTop from '~/src/freeway/hooks/useScrollToTop';
import LocalizedLink from 'features/routing/LocalizedLink';
import FooterLanguageSelector from '~/src/components/layout/FooterLanguageSelector';
import React from 'react';
import { Trans } from '@lingui/react';
import freewayRoutesTyped from 'features/routing/routes/freewayRoutesTyped';
import { routes } from '~/src/utils/routing';
import { useDispatch, useSelector } from 'react-redux';
import { screenSelector } from 'features/redux/selectors/screen';
import { openDesktopOrPhoneModal } from '~/src/components/modal/utils';
import { ModalType } from '~/src/components/modal/modalTypes';
import { t } from '@lingui/macro';
import { settingsSelector } from 'features/redux/selectors/settings';
import SecurityIcon from 'ui/icons/ic_security.svg';
import { navUnderlineHighlightCss } from '~/src/components/layout/headers/styles';
import FBOutline from 'ui/icons/ic_facebook-outline.svg';
import IGOutline from 'ui/icons/ic_instagram-outline.svg';
import LinkedIn from 'ui/icons/ic_linkedin.svg';
import Youtube from 'ui/icons/ic_youtube.svg';
import { useCustomerSupportInfo } from '~/src/freeway/hooks/useCustomerSupportInfo';

const getMenuItems = () => [
  {
    heading: t({
      id: 'footer.fwc.campers',
      message: 'Campers',
    }),
    items: [
      {
        title: t({
          id: 'footer.fwc.renterACamer',
          message: 'Rent a camper',
        }),
        link: routes.search,
      },
      {
        title: t({
          id: 'footer.fwc.buyACamper',
          message: 'Buy a camper',
        }),
        link: freewayRoutesTyped.buyCamper,
      },
      {
        title: t({
          id: 'footer.fwc.campingWithDog',
          message: 'Camping with dog',
        }),
        link: routes.dynamicSegmentNew,
        linkParams: {
          en: {
            id: '341',
            slug: 'dog-friendly-rv-rental',
          },
          de: {
            id: '267',
            slug: 'wohnmobil-mieten-mit-hund',
          },
          it: {
            id: '266',
            slug: 'noleggio-camper-con-cane',
          },
        },
      },
      {
        title: t({
          id: 'footer.fwc.deals',
          message: 'Deals & special offers',
        }),
        link: routes.blogDetail,
        linkParams: {
          en: {
            id: '539',
            slug: 'last-minute-deals-special-offers',
          },
          de: {
            id: '540',
            slug: 'last-minute-deals-angebote',
          },
          it: {
            id: '541',
            slug: 'last-minute-offerte-speciali',
          },
        },
      },
    ],
  },
  {
    heading: t({
      id: 'footer.fwc.joinUs',
      message: 'Join us',
    }),
    items: [
      {
        title: t({
          id: 'footer.fwc.becomeAPartner',
          message: 'Become a Partner',
        }),
        link: routes.blogDetail,
        linkParams: {
          'en-de': {
            id: '563',
            slug: 'become-a-partner',
          },
          'en-it': {
            id: '927',
            slug: 'become-partner',
          },
          'de-de': {
            id: '564',
            slug: 'partner-werden',
          },
          'it-it': {
            id: '565',
            slug: 'diventare-partner',
          },
        },
      },
      {
        title: t({
          id: 'footer.fwc.jobOpenings',
          message: 'Job openings',
        }),
        link: freewayRoutesTyped.aboutUs,
        hash: '#job',
      },
      {
        title: t({
          id: 'footer.fwc.aboutUs',
          message: 'About us',
        }),
        link: freewayRoutesTyped.aboutUs,
      },
    ],
  },
  {
    heading: t({
      id: 'footer.fwc.support',
      message: 'Support',
    }),
    items: [
      {
        title: t({
          id: 'footer.fwc.faq',
          message: 'FAQ',
        }),
        link: routes.faq,
      },
      {
        title: t({
          id: 'footer.fwc.helpCenter',
          message: 'Help Center',
        }),
        link: freewayRoutesTyped.helpCenter,
      },
      {
        title: t({
          id: 'footer.fwc.contact',
          message: 'Contact',
        }),
        link: routes.faq,
        hash: '#contact',
      },
      {
        title: t({
          id: 'footer.fwc.climate',
          message: 'Climate-conscious travel',
        }),
        link: routes.blogDetail,
        linkParams: {
          'en-de': {
            id: '548',
            slug: 'climate-conscious-travel',
          },
          'en-it': {
            id: '920',
            slug: 'climate-conscious',
          },
          'de-de': {
            id: '549',
            slug: 'klimabewusst-reisen',
          },
          'it-it': {
            id: '550',
            slug: 'attenti-all-ambiente',
          },
        },
      },
      {
        title: t({
          id: 'footer.fwc.blog',
          message: 'Blog',
        }),
        link: freewayRoutesTyped.blog,
      },
      {
        title: t({
          id: 'footer.fwc.photoCredits',
          message: 'Photo credits',
        }),
        link: routes.blogDetail,
        linkParams: {
          'en-de': {
            id: '560',
            slug: 'photo-credits',
          },
          'en-it': {
            id: '925',
            slug: 'photo-credits-links',
          },
          'de-de': {
            id: '561',
            slug: 'bildernachweis',
          },
          'it-it': {
            id: '562',
            slug: 'crediti-fotografici',
          },
        },
      },
      {
        title: t({
          id: 'footer.fwc.newsletter',
          message: 'Newsletter',
        }),
        link: freewayRoutesTyped.newsletter,
      },
      {
        title: t({
          id: 'footer.fwc.whistleblowingSystem',
          message: 'Whistleblowing System',
        }),
        href: 'https://freeway-camper.hintbox.eu/',
        target: '_blank',
      },
    ],
  },
];

const SocialIcons = () => (
  <SocialWrapper $gap={tokens.spacing.spacingM}>
    <a className="noUnderline" href="https://www.facebook.com/FWCFreewayCamper/" target="_blank" rel="noreferrer">
      <FBOutline height={20} />
    </a>
    <a className="noUnderline" href="https://www.instagram.com/freeway_camper/" target="_blank" rel="noreferrer">
      <IGOutline height={20} />
    </a>

    <a
      className="noUnderline adjustStroke"
      href="https://www.youtube.com/channel/UCjeTrbXy4wXewWw_kcWvNMA"
      target="_blank"
      rel="noreferrer"
    >
      <Youtube height={20} />
    </a>
    <a
      className="noUnderline adjustStroke"
      href="https://www.linkedin.com/company/freewaycamper/"
      target="_blank"
      rel="noreferrer"
    >
      <LinkedIn stroke="#F5F6F6" height={20} />
    </a>
  </SocialWrapper>
);

export default function Footer() {
  const [isSmallScreen] = useBreakpoints(SMALL_SCREEN_QUERY);
  const scrollToTop = useScrollToTop();
  const dispatch = useDispatch();
  const settings = useSelector(settingsSelector);
  const screen = useSelector(screenSelector);

  const { phone, openingTime } = useCustomerSupportInfo();

  return (
    <Box $background={tokens.color.backgrounds.surfaceSection}>
      <Wrapper
        $padding={tokens.spacing.spacing2Xl}
        $position="relative"
        $mdMin={{
          $pt: tokens.spacing.spacing5Xl,
          $pb: tokens.spacing.spacing2Xl,
          $pl: '0px',
          $pr: '0px',
        }}
        $maxWidth="1200px"
        $mx="auto"
      >
        <FlexColMdMax $gap={tokens.spacing.spacingSection} $gapMdMax={tokens.spacing.spacing4Xl}>
          <FlexCol>
            <FlexJustifySpaceBetweenAlignCenter $pb={tokens.spacing.spacingXl}>
              <LogoWrapper>
                <FreewayLogo width="140" height="118" />
              </LogoWrapper>
              <HiddenOnDesktop $zIndex={100}>
                <FlexCenterBoth
                  onClick={scrollToTop}
                  $cursor="pointer"
                  $borderRadius="50%"
                  $background={tokens.color.backgrounds.surfaceBg}
                  $height="40px"
                  $width="40px"
                >
                  <ArrowUpIcon height={17} />
                </FlexCenterBoth>
              </HiddenOnDesktop>
            </FlexJustifySpaceBetweenAlignCenter>
            <HiddenOnDesktop>
              <Box
                $pb={tokens.spacing.spacing4Xl}
                $mdMin={{
                  $pb: tokens.spacing.spacing5Xl,
                }}
                $ml="auto"
                $mt="auto"
              >
                <SocialIcons />
              </Box>
            </HiddenOnDesktop>
            <Typography
              $pb={tokens.spacing.spacing4Xl}
              as="a"
              $color={tokens.color.text.textContrastSecondary}
              $tDecoration="underline"
              href="mailto:info@freeway-camper.com"
            >
              info@freeway-camper.com
            </Typography>
            <FlexCol
              $borderBottom="1px solid"
              $borderColor={tokens.color.items.separatorLight}
              $pb={tokens.spacing.spacing4Xl}
              $mdMin={{
                $pb: tokens.spacing.spacing5Xl,
              }}
              $gap={tokens.spacing.spacingS}
            >
              <div>
                <Typography as="a" $color={tokens.color.text.textContrastSecondary} href={`tel:${phone}`}>
                  {phone}
                </Typography>
              </div>
              {openingTime.map((time) => (
                <div key={time}>{time}</div>
              ))}
            </FlexCol>
            <Box
              $mdMax={{
                $borderBottom: '1px solid',
                $borderColor: tokens.color.items.separatorLight,
                $pb: tokens.spacing.spacing4Xl,
              }}
              $pt={tokens.spacing.spacing4Xl}
            >
              <FooterLanguageSelector />
            </Box>
          </FlexCol>
          <RightSide>
            <FlexJustifySpaceBetweenAlignCenter
              $mdMin={{
                $borderBottom: '1px solid',
                $borderTop: 'none',
                $pb: tokens.spacing.spacing4Xl,
                $mb: tokens.spacing.spacing4Xl,
                $pt: '0px',
                $mt: '0px',
              }}
              $mt={tokens.spacing.spacing4Xl}
              $pt={tokens.spacing.spacing4Xl}
              $borderTop="1px solid"
              $borderColor={tokens.color.items.separatorLight}
            >
              <FlexCol $width="100%">
                <Typography $fSize="12px" $pb={tokens.spacing.spacingL}>
                  <Trans id="mainPage.footer.payPossibilities" />
                </Typography>
                <Flex $gap="10px">
                  <MastercardIcon />
                  <PaypalIcon />
                  <GiroIcon />
                  <KlarnaIcon />
                  <Przelwy24Icon />
                  <SepaIcon />
                  <TransferIcon />
                  <VisaIcon />
                </Flex>
              </FlexCol>
              <HiddenOnMobile>
                <FlexCenterBoth
                  onClick={scrollToTop}
                  $cursor="pointer"
                  $borderRadius="50%"
                  $background={tokens.color.backgrounds.surfaceBg}
                  $height="56px"
                  $width="56px"
                >
                  <ArrowUpIcon height={20} />
                </FlexCenterBoth>
              </HiddenOnMobile>
            </FlexJustifySpaceBetweenAlignCenter>
            <FlexColMdMax
              $zIndex={1}
              $gap={tokens.spacing.spacing4Xl}
              $mdMin={{
                $gap: '100px',
              }}
              $pt={tokens.spacing.spacingL}
            >
              {getMenuItems().map((menuCategory) => (
                <FlexCol key={menuCategory.heading}>
                  {isSmallScreen ? (
                    <AccordionFWC
                      theme="light"
                      heading={
                        <Headline $color={tokens.color.text.textContrastSecondary} $size="h6">
                          {menuCategory.heading}
                        </Headline>
                      }
                    >
                      <FlexCol $gap={tokens.spacing.spacingXl}>
                        {menuCategory.items.map((item) => (
                          <div key={item.title}>
                            {item.href ? (
                              <a href={item.href} target={item.target}>
                                {item.title}
                              </a>
                            ) : (
                              <LocalizedLink
                                newWindow={item.target === '_blank'}
                                hash={item.hash}
                                href={item.link}
                                linkParams={
                                  item.linkParams?.[settings.language] ||
                                  item.linkParams?.[`${settings.language}-${settings.country}`]
                                }
                              >
                                {item.title}
                              </LocalizedLink>
                            )}
                          </div>
                        ))}
                      </FlexCol>
                    </AccordionFWC>
                  ) : (
                    <>
                      <Headline $pb={tokens.spacing.spacing4Xl} $size="h6">
                        {menuCategory.heading}
                      </Headline>
                      <FlexCol $gap={tokens.spacing.spacingXl}>
                        {menuCategory.items.map((item) => (
                          <div key={item.title}>
                            {item.href ? (
                              <a href={item.href} target={item.target}>
                                {item.title}
                              </a>
                            ) : (
                              <LocalizedLink
                                newWindow={item.target === '_blank'}
                                hash={item.hash}
                                href={item.link}
                                linkParams={
                                  item.linkParams?.[settings.language] ||
                                  item.linkParams?.[`${settings.language}-${settings.country}`]
                                }
                              >
                                {item.title}
                              </LocalizedLink>
                            )}
                          </div>
                        ))}
                      </FlexCol>
                    </>
                  )}
                </FlexCol>
              ))}
            </FlexColMdMax>
            <HiddenOnMobile $display="flex" $pt="10px">
              <Box $ml="auto" $mt="auto">
                <SocialIcons />
              </Box>
            </HiddenOnMobile>
          </RightSide>
        </FlexColMdMax>
        <CopyrightWrapper
          $mdMin={{
            $pt: tokens.spacing.spacingXl,
            $mt: tokens.spacing.spacingXl,
          }}
          $pt={tokens.spacing.spacingL}
          $mt={tokens.spacing.spacing4Xl}
          $borderTop="1px solid"
          $borderColor={tokens.color.items.separatorLight}
        >
          <div>
            ©{new Date().getFullYear()}{' '}
            <Trans
              id="footer.freeway.copyright"
              components={{
                0: <span>|</span>,
              }}
              message="Freeway Camper <0/> All rights reserved."
            />
          </div>
          <Flex className="links" $gap={tokens.spacing.spacingS}>
            <LocalizedLink href={freewayRoutesTyped.legalNotice}>
              <Trans id="page.legalNotice.fwc.heading" message="Imprint / Legal notice" />
            </LocalizedLink>
            <div>|</div>
            <LocalizedLink href={routes.tos}>
              <Trans id="tabs.termsAndConditions" />
            </LocalizedLink>
            <div>|</div>
            <Box
              as="a"
              href="#"
              $cursor="pointer"
              onClick={(e) => {
                e.preventDefault();
                openDesktopOrPhoneModal(ModalType.COOKIES_SETTINGS, screen, dispatch);
              }}
            >
              <Trans id="tabs.personalDataProtection" />
            </Box>
            <div>|</div>
            <LocalizedLink href={routes.privacy}>
              <Flex $gap={tokens.spacing.spacingS}>
                <SecurityIcon height={16} />
                <Trans id="page.privacyPolicy.fwc.heading" message="Privacy policy" />
              </Flex>
            </LocalizedLink>
          </Flex>
        </CopyrightWrapper>
        <MountainWrapper>
          <FooterMountain />
        </MountainWrapper>
      </Wrapper>
    </Box>
  );
}

const SocialWrapper = styled(Flex)`
  a:hover svg {
    fill: ${resolveThemeValue(tokens.color.text.textLink)};
  }

  a.adjustStroke:hover {
    svg {
      stroke: ${resolveThemeValue(tokens.color.text.textLink)};
      fill: none;
    }
  }
`;

const CopyrightWrapper = styled(FlexJustifySpaceBetween)`
  font-size: 12px;
  ${mediaMdMax} {
    gap: ${resolveThemeValue(tokens.spacing.spacingL)};
    flex-direction: column;

    .links {
      flex-wrap: wrap;
    }
  }

  a {
    position: relative;
    ${navUnderlineHighlightCss}
  }
`;

const LogoWrapper = styled(Box)`
  ${mediaMdMax} {
    svg {
      height: 64px;
      width: 76px;
    }
  }
`;

const RightSide = styled(FlexCol)`
  flex: 1;
  ${mediaMdMax} {
    flex-flow: column-reverse;
  }

  a:not(.noUnderline) {
    position: relative;
    ${navUnderlineHighlightCss}
  }
`;

const MountainWrapper = styled.div`
  position: absolute;
  opacity: 0.05;

  right: -50%;
  top: -10px;
  ${mediaMdMin} {
    right: 0;
    top: 30%;
  }
`;

const Wrapper = styled(FlexCol)`
  overflow: hidden;
  color: ${resolveThemeValue(tokens.color.text.textContrastSecondary)};
`;
