const PlFlagImage = () => (
  <svg width="24" height="16" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="nonzero" fill="none">
      <path fill="#FFF" d="M0 0h24v16H0z" />
      <path fill="#DC143C" d="M0 8h24v8H0z" />
    </g>
  </svg>
);

export default PlFlagImage;
