import { useSelector } from 'react-redux';
import { settingsSelector } from 'features/redux/selectors/settings';

export const useCustomerSupportInfo = () => {
  const settings = useSelector(settingsSelector);

  const phones = {
    it: '+39 02 3858 2379',
    de: '+49(0)89 889 970 86',
  };

  const openingTimes = {
    'de-de': ['Mo - Fr: 8-19 Uhr', 'Sa - So: 10-18 Uhr'],
    'en-de': ['Mon - Fri: 8am - 7pm', 'Sat - Sun: 10am - 6pm'],
    'en-it': ['Mon - Fri: 8am - 7pm', 'Sat - Sun: 10am - 6pm'],
    'it-it': ['Lun - Dom: 9:00 - 17:00'],
  };

  const phone = phones[settings.country] || phones.de;
  const openingTime = openingTimes[`${settings.language}-${settings.country}`] || openingTimes['en-de'];

  return {
    phone,
    openingTime,
  };
};
