import React, { ReactElement, FC, ReactNode } from 'react';
import Header from '~/src/components/layout/Header';
import Footer from '~/src/components/layout/Footer';
import SpecialOfferBar from './SpecialOfferBar';
import Block from './cookies/Block';
import CookiesNotice from './cookies/BlockNotice';
import SpecialOfferFWC from '~/src/components/layout/SpecialOfferFWC';

type LayoutProps = {
  children: ReactNode;
  scrollPosition?: boolean;
  changeLanguageBar?: {
    element: ReactNode;
    isVisible: boolean;
  };
  forceWhiteVersion?: boolean;
  showSpecialOffer?: boolean;
  showSpecialOfferFWC?: boolean;
  className?: string;
};

const Layout: FC<LayoutProps> = ({
  children,
  scrollPosition,
  changeLanguageBar,
  forceWhiteVersion = false,
  showSpecialOffer = true,
  showSpecialOfferFWC = false,
  className,
}) => (
  <>
    {showSpecialOffer && <SpecialOfferBar />}
    {showSpecialOfferFWC && <SpecialOfferFWC />}
    {changeLanguageBar && changeLanguageBar.element}
    <Header forceWhiteVersion={forceWhiteVersion} scrollPosition={scrollPosition} />

    <main className={className}>{children}</main>

    <Footer />

    <CookiesNotice />
    <Block />
  </>
);

export default Layout;
