import styled from 'styled-components';
import { deviceMaxWidth } from '~/src/styles/breakpoints';

export const SubMenuHeading = styled.h4`
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 0;
  color: var(--color-dark-blue);

  &.reviews {
    display: flex;
    align-items: center;
  }
  &.footer {
    @media ${deviceMaxWidth.phone} {
      cursor: pointer;
    }
  }
`;

export const UL = styled.ul`
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const LI = styled.li`
  display: flex;
  flex-direction: column;
  gap: 32px;

  &.main {
    width: 100%;
    max-width: 180px;
    gap: 32px;

    @media ${deviceMaxWidth.phone} {
      max-width: inherit;
    }
  }

  &.logo-social {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const MenuItem = styled.a`
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  color: var(--color-dark-blue);

  text-decoration: none;
  margin-bottom: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
