import { Country } from './routing';

export const facebookLink = {
  [Country.cz]: 'https://www.facebook.com/campiri1/',
  [Country.es]: 'https://www.facebook.com/campiri1/',
  [Country.sk]: 'https://www.facebook.com/campiri1/',
  [Country.pl]: 'https://www.facebook.com/Campiripl-109044331551095',
};

export const instagramLink = {
  [Country.cz]: 'https://www.instagram.com/campiri/',
  [Country.es]: 'https://www.instagram.com/campiri/',
  [Country.sk]: 'https://www.instagram.com/campiri/',
  [Country.pl]: 'https://www.instagram.com/campiri.pl',
};

export const linkedInLink = {
  [Country.cz]: 'https://www.linkedin.com/company/campiri/',
  [Country.es]: 'https://www.linkedin.com/company/campiri/',
  [Country.sk]: 'https://www.linkedin.com/company/campiri/',
  [Country.pl]: 'https://www.linkedin.com/company/campiri/',
};
