import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/react';
import { Country, Language } from '~/src/utils/routing';
import { useLocalizedRouter } from '~/src/components/LocalizedLink';
import { SettingsProps } from '~/src/redux/reducers/settings';
import DropDown, { DropDownContainer, DropDownHeaderTitle } from '../inputs/DropDown';
import CzFlagImage from './flags/CzFlagImage';
import PlFlagImage from './flags/PlFlagImage';
import { setCountry, setCurrency, setLanguage } from '~/src/redux/actions/settings';
import { getCurrencyFromCountry } from '~/src/utils/currency';
import EnFlagImage from './flags/EnFlagImage';
import SkFlagImage from './flags/SkFlagImage';
import EsFlagImage from './flags/EsFlagImage';
import { useCodebase } from 'features/hooks/useCodebase';
import { deFlag, itFlag } from '~/src/components/roadTripsLP/utils';

interface Props {
  settings: SettingsProps;
  dispatch: any;
}

const csLangSelectItem = {
  label: 'Čeština',
  value: Language.cs,
  icon: <CzFlagImage />,
};
const plLangSelectItem = {
  label: 'Polski',
  value: Language.pl,
  icon: <PlFlagImage />,
};

const skLangSelectItem = {
  label: 'Slovenčina',
  value: Language.sk,
  icon: <SkFlagImage />,
};

const enLangSelectItem = {
  label: 'English',
  value: Language.en,
  icon: <EnFlagImage />,
};

const esLangSelectItem = {
  label: 'Español ',
  value: Language.es,
  icon: <EsFlagImage />,
};

const deLangSelectItem = {
  label: 'German',
  value: Language.de,
  icon: deFlag,
};

const itLangSelectItem = {
  label: 'Italian ',
  value: Language.it,
  icon: itFlag,
};

const mutationDropDownOptions = (isFWC: boolean) =>
  [
    !isFWC && {
      label: 'Česko (CZK)',
      value: Country.cz,
      icon: <CzFlagImage />,
    },
    !isFWC && {
      label: 'Polska (PLN)',
      value: Country.pl,
      icon: <PlFlagImage />,
    },
    !isFWC && {
      label: 'Slovensko (EUR)',
      value: Country.sk,
      icon: <SkFlagImage />,
    },
    !isFWC && {
      label: 'España (EUR)',
      value: Country.es,
      icon: <EsFlagImage />,
    },
    isFWC && {
      label: 'Germany (EUR)',
      value: Country.de,
      icon: deFlag,
    },
    isFWC && {
      label: 'Italy (EUR)',
      value: Country.it,
      icon: itFlag,
    },
  ].filter(Boolean);

function FooterLanguageSelector({ settings, dispatch }: Props) {
  const [isWider, setIsWider] = useState(false);
  const pathNames = ['/search', '/campervan/[carId]'];
  const isFWC = useCodebase('fwc');

  const router = useLocalizedRouter();

  const getLangSelectItems = () => {
    switch (settings.country) {
      case Country.pl:
        return [plLangSelectItem, enLangSelectItem];
      case Country.sk:
        return [skLangSelectItem, enLangSelectItem];
      case Country.es:
        return [esLangSelectItem, enLangSelectItem];
      case Country.it:
        return [itLangSelectItem, enLangSelectItem];
      case Country.de:
        return [deLangSelectItem, enLangSelectItem];
      case Country.cz:
      default:
        return [isFWC ? deLangSelectItem : csLangSelectItem, enLangSelectItem];
    }
  };

  const languageDropDownOptions = getLangSelectItems();
  useEffect(() => {
    if (pathNames.includes(router.pathname)) {
      setIsWider(true);
    } else {
      setIsWider(false);
    }
  }, [router.pathname]);

  const onLanguageChanged = (opt, country = null) => {
    const selectedLang = Language[opt.value];
    dispatch(setLanguage(selectedLang));

    if (country) {
      const selectedCountry = country.value;
      dispatch(setCurrency(getCurrencyFromCountry(selectedCountry)));
      dispatch(setCountry(selectedCountry));
      router.changeLanguage(selectedLang, `${selectedLang}-${selectedCountry}`);
    } else {
      router.changeLanguage(selectedLang, `${selectedLang}-${settings.country}`);
      dispatch(setCurrency(getCurrencyFromCountry(settings.country)));
      dispatch(setCountry(settings.country));
    }
    i18n.activate(selectedLang);
  };

  const onMutationChanged = (opt) => {
    const country = opt.value;
    switch (country) {
      case Country.cz:
        onLanguageChanged({ value: Language.cs }, { value: country });
        break;
      case Country.pl:
        onLanguageChanged({ value: Language.pl }, { value: country });
        break;
      case Country.sk:
        onLanguageChanged({ value: Language.sk }, { value: country });
        break;
      case Country.de:
        onLanguageChanged({ value: Language.de }, { value: country });
        break;
      case Country.it:
        onLanguageChanged({ value: Language.it }, { value: country });
        break;
      case Country.es:
        onLanguageChanged({ value: Language.es }, { value: country });
        break;
      default:
        break;
    }
  };

  const options = mutationDropDownOptions(isFWC);
  return (
    <DropDownBox>
      <DropDown
        options={options}
        selected={options.find((i) => i.value === settings.country)}
        headerPrefix="icon"
        onSelectedChanged={(opt) => onMutationChanged(opt)}
      />
      <DropDown
        options={languageDropDownOptions}
        selected={languageDropDownOptions.find((i) => i.value === settings.language)}
        headerPrefix={
          <>
            <Trans id="label.language" message="Language" />
            {' :'}
          </>
        }
        onSelectedChanged={(opt) => onLanguageChanged(opt)}
      />
    </DropDownBox>
  );
}
const DropDownBox = styled.div`
  ${DropDownHeaderTitle} {
    font-weight: bold;
  }
  ${DropDownContainer} {
    min-width: max-content;
    flex-shrink: 0;
  }
  > div {
    &:first-of-type {
      margin-bottom: 16px;
    }
  }
`;

const mapStateToProps = (state) => ({
  settings: state.settings,
});

export default connect(mapStateToProps, (dispatch) => ({ dispatch }))(FooterLanguageSelector);
