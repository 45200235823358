/* eslint-disable max-len */
import React, { SVGProps } from 'react';

const LinkedInIcon: React.FC<SVGProps<SVGElement>> = React.memo(props => (
  // @ts-ignore
  <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M4.725 18.8583H0.674999V7.25H4.725V18.8583ZM13.1333 11.0333C12.6604 11.0333 12.2068 11.2212 11.8723 11.5557C11.5379 11.8901 11.35 12.3437 11.35 12.8167V18.8583H7.09167V7.25H11.35V8.575C12.3481 7.73005 13.6091 7.25866 14.9167 7.24167C17.5583 7.24167 19.3917 9.2 19.3917 12.9167V18.8583H14.9167V12.8167C14.9178 12.5818 14.8724 12.349 14.7833 12.1317C14.6942 11.9143 14.563 11.7168 14.3973 11.5503C14.2316 11.3838 14.0346 11.2517 13.8177 11.1615C13.6008 11.0714 13.3682 11.025 13.1333 11.025V11.0333ZM4.8 3.23333C4.8 3.64538 4.67781 4.04817 4.44889 4.39077C4.21997 4.73337 3.8946 5.0004 3.51392 5.15808C3.13324 5.31577 2.71435 5.35702 2.31023 5.27664C1.9061 5.19625 1.53489 4.99783 1.24353 4.70647C0.952167 4.41511 0.753749 4.0439 0.673363 3.63977C0.592977 3.23565 0.634234 2.81676 0.791917 2.43608C0.949599 2.0554 1.21663 1.73003 1.55923 1.50111C1.90183 1.27219 2.30462 1.15 2.71667 1.15C3.26631 1.1544 3.79194 1.37583 4.17904 1.76606C4.56615 2.15628 4.78335 2.68368 4.78333 3.23333H4.8Z"
        stroke="current"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="20" height="20" fill="current" />
      </clipPath>
    </defs>
  </svg>
));

LinkedInIcon.displayName = 'LinkedInIcon';
export default LinkedInIcon;
