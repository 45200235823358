/* eslint-disable max-len */
import React, { SVGProps } from 'react';

const SecurityIcon: React.FC<SVGProps<SVGElement>> = React.memo(props => (
  // @ts-ignore
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_939_1634)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 0C15.7761 0 16 0.223858 16 0.5V7C16 11.5033 13.278 14.6093 8.12867 15.9833C8.08665 15.9943 8.04342 16 8 16C7.95631 16 7.91283 15.9941 7.87067 15.9827C2.722 14.6093 0 11.5033 0 7V0.5C0 0.223858 0.223858 0 0.5 0H15.5ZM15 1H1V7C1 11.0207 3.35467 13.706 8 14.982C12.6453 13.7053 15 11.0207 15 7V1ZM8 2C9.654 2 11 3.346 11 5V6.086C11.5982 6.2992 11.9983 6.86491 12 7.5V10.5C11.9993 11.3281 11.3281 11.9993 10.5 12H5.5C4.67188 11.9993 4.00074 11.3281 4 10.5V7.5C4 6.86 4.41 6.29467 5 6.086V5C5 3.346 6.346 2 8 2ZM10.5 7H5.5C5.22386 7 5 7.22386 5 7.5V10.5C5 10.776 5.224 11 5.5 11H10.5C10.7761 11 11 10.7761 11 10.5V7.5C11 7.22386 10.7761 7 10.5 7ZM8.532 8.47067C8.74532 8.68571 8.8085 9.00788 8.6922 9.28757C8.5759 9.56725 8.3029 9.74963 8 9.75C7.58667 9.75 7.25 9.41333 7.25 9C7.25103 8.69673 7.43427 8.42382 7.71459 8.30808C7.9949 8.19234 8.31731 8.25647 8.532 8.47067ZM8 3C6.89733 3 6 3.89733 6 5V6H10V5C10 3.89733 9.10267 3 8 3Z"
        fill="#6F6363"
      />
    </g>
    <defs>
      <clipPath id="clip0_939_1634">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
));

SecurityIcon.displayName = 'SecurityIcon';
export default SecurityIcon;
