/* eslint-disable max-len */
const SkFlagImage = () => (
  <svg width="24" height="16" viewBox="0 0 24 16" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-29.7%" y="-34.5%" width="159.5%" height="169%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="16" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.121568627 0 0 0 0 0.133333333 0 0 0 0 0.266666667 0 0 0 0.2 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g filter="url(#a)" transform="translate(-16 -120)" fillRule="nonzero" fill="none">
      <path fill="#EE1C25" d="M16 120h24v16H16z" />
      <path fill="#0B4EA2" d="M16 120h24v10.667H16z" />
      <path fill="#FFF" d="M16 120h24v5.333H16z" />
      <path
        d="M26.493 123.76H19.903l-.023.215c-.005.047-.12 1.172-.12 3.645 0 1.162.393 2.187 1.167 3.046.543.602 1.251 1.11 2.167 1.55l.104.05.104-.05c.915-.44 1.624-.948 2.167-1.55a4.423 4.423 0 0 0 1.167-3.046c0-2.473-.116-3.598-.12-3.645l-.023-.215z"
        fill="#FFF"
      />
      <path
        d="M23.198 132c-1.316-.634-3.198-1.892-3.198-4.38s.119-3.62.119-3.62h6.158s.119 1.132.119 3.62c0 2.488-1.882 3.746-3.198 4.38z"
        fill="#EE1C25"
      />
      <path
        d="M23.48 126.968c.356.005 1.05.02 1.67-.188 0 0-.017.222-.017.48s.017.48.017.48c-.568-.19-1.269-.195-1.67-.19v1.375h-.563v-1.375c-.402-.005-1.103 0-1.67.19 0 0 .016-.222.016-.48s-.016-.48-.016-.48c.618.207 1.314.193 1.67.188v-.864a3.95 3.95 0 0 0-1.322.19s.016-.221.016-.48c0-.257-.016-.479-.016-.479a3.95 3.95 0 0 0 1.32.19c-.016-.547-.176-1.236-.176-1.236s.328.026.46.026c.13 0 .458-.026.458-.026s-.16.69-.176 1.236c.325.003.792-.013 1.32-.19 0 0-.016.222-.016.48s.016.48.016.48a3.95 3.95 0 0 0-1.321-.19v.863z"
        fill="#FFF"
      />
      <path
        d="M23.197 128.776c-.662 0-1.017.919-1.017.919s-.197-.436-.738-.436c-.366 0-.635.326-.807.627.666 1.059 1.728 1.712 2.562 2.114.835-.402 1.898-1.055 2.563-2.114-.171-.301-.44-.627-.806-.627a.814.814 0 0 0-.739.436s-.355-.92-1.018-.92z"
        fill="#0B4EA2"
      />
    </g>
  </svg>
);

export default SkFlagImage;
