import React from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import LocalizedLink from '~/src/components/LocalizedLink';
import { LI, MenuItem, SubMenuHeading, UL } from './styles';
import { getFooterCampervansMenuItems, getFooterLinksTitleByKey } from './headers/utils';
import { deviceMaxWidth, deviceMinWidth } from '~/src/styles/breakpoints';
import { useTypedSelector } from '~/src/redux/store';
import { settingsSelector } from '~/src/redux/selectors/settings';
import { Country } from '~/src/utils/routing';

const FooterLinks: React.FC = () => {
  const settings = useTypedSelector(settingsSelector);

  const toggleVisibility = (event: React.MouseEvent<HTMLElement>) => {
    event.currentTarget.parentElement.classList.toggle('expanded');
  };
  if (!getFooterCampervansMenuItems()[settings.country]) return null;

  return (
    <LinksUL>
      {Object.entries(getFooterCampervansMenuItems()[settings.country])?.map(([key, value]) => {
        if (key === 'owners' && settings.country !== Country.cz) return null;

        return (
          <React.Fragment key={key}>
            <LI>
              <SubMenuHeading className='footer' onClick={e => toggleVisibility(e)}>
                {getFooterLinksTitleByKey(key)}
                <IconDown className='icon icon-select-arrow' />
              </SubMenuHeading>
              <UL className='box'>
                {Array.isArray(value) &&
                  value.map(link => {
                    if (link?.supportedLanguage && link?.supportedLanguage !== settings?.language) return <></>;

                    return (
                      <LI key={`${key}-${link.label}`}>
                        {typeof link.route === 'string' ? (
                          <LinkToExternal href={link.route} target='_blank'>
                            <MenuItem as='span'>
                              <Trans id={link.label} />
                            </MenuItem>
                          </LinkToExternal>
                        ) : (
                          <LocalizedLink hash={link?.hash} prefetch={false} href={link.route} passHref={true}>
                            <MenuItem>
                              <Trans id={link.label} />
                            </MenuItem>
                          </LocalizedLink>
                        )}
                      </LI>
                    );
                  })}
              </UL>
            </LI>
          </React.Fragment>
        );
      })}
    </LinksUL>
  );
};

const LinksUL = styled(UL)`
  display: flex;
  justify-content: space-between;

  flex-direction: row;
  gap: 32px;
  width: 70%;
  @media ${deviceMaxWidth.tablet} {
    width: 85%;
  }
  @media ${deviceMaxWidth.phone} {
    flex-direction: column;
    width: 100%;

    ${LI}:not(.expanded) ${UL} {
      display: none;
    }

    li {
      h4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
`;

const LinkToExternal = styled.a`
  color: var(--color-dark-blue);
`;

const IconDown = styled.i`
  display: inline-block;
  margin-left: 10px;

  ${LI}.expanded & {
    transform: rotate(180deg);
  }

  font-size: 16px;
  @media ${deviceMinWidth.phone} {
    display: none;
  }
`;

export default FooterLinks;
