// @flow

import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useOnClickOutside } from '~/src/hooks/useOnClickOutside';
import { deviceMaxWidth } from '~/src/styles/breakpoints';
import { resolveInCodebase, resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';
import { Typography } from 'ui/primitives/typography';
import { useCodebase } from 'features/hooks/useCodebase';

type DropdownOption = {
  label: string;
  value: string | number;
  icon?: any;
};

type DropdownPosition = 'top' | 'bottom';

type DropdownHeaderPrefix = 'icon' | string | null | any;

interface Props {
  options: DropdownOption[];
  selected?: DropdownOption;
  position?: DropdownPosition;
  headerPrefix?: DropdownHeaderPrefix;
  onSelectedChanged: (opt: DropdownOption) => void;
}

const DropDown = ({ options, selected = null, position = 'top', headerPrefix = null, onSelectedChanged }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [selectedOption, setSelectedOption] = useState<DropdownOption>(selected);
  const headerRef = useRef(null);
  const listRef = useRef(null);
  const isFWC = useCodebase('fwc');

  const toggling = () => setIsOpen((prev) => !prev);
  const isSelectedValue = (value: string | number) => value === selectedOption?.value;

  const onOptionClicked = (value: DropdownOption) => () => {
    setSelectedOption(value);
    setIsOpen(false);
    onSelectedChanged(value);
  };

  const getHeaderPrefix = () => {
    if (!headerPrefix) {
      return '';
    }
    if (headerPrefix && headerPrefix !== 'icon') {
      return <span>{headerPrefix}</span>;
    }
    return <FlagImg>{selectedOption?.icon}</FlagImg>;
  };

  useOnClickOutside(listRef, (e: React.MouseEvent<any>) => {
    setIsOpen(false);
  });

  useEffect(() => {
    setHeaderHeight(headerRef.current.clientHeight);
  }, []);

  return (
    <DropDownContainer ref={listRef}>
      <DropDownHeader
        as="div"
        $color={isFWC ? tokens.color.text.textContrastSecondary : '#1F2244'}
        onClick={toggling}
        ref={headerRef}
      >
        <DropDownHeaderTitle>
          {getHeaderPrefix()} {selectedOption?.label || options[0].label}
        </DropDownHeaderTitle>
        <IconDown className={`icon icon-select-arrow ${isOpen ? 'up' : ''}`} />
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer position={position} headerHeight={headerHeight}>
          <DropDownList>
            {options.map((option) => (
              <>
                <ListItem
                  onClick={onOptionClicked(option)}
                  key={Math.random()}
                  selected={isSelectedValue(option.value)}
                >
                  {option?.icon && <FlagImg>{option?.icon}</FlagImg>}
                  <span>{option.label}</span>
                </ListItem>
              </>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};

export const DropDownContainer = styled.div`
  margin: 0 auto;
  position: relative;
`;

export const DropDownHeader = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  font-family: var(--font-poppins);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: 400;
    display: inline-block;
    margin-right: 5px;
  }
`;

export const DropDownHeaderTitle = styled.div`
  display: inherit;
`;

const DropDownListContainer = styled.div<{ headerHeight: number; position: string }>`
  position: absolute;
  z-index: 100;
  left: 0;
  bottom: ${(props) => (props.position === 'top' ? `${props.headerHeight + 8}px` : 'initial')};
  top: ${(props) => (props.position === 'bottom' ? `${props.headerHeight + 8}px` : 'initial')};
  @media ${deviceMaxWidth.phone} {
    width: 100%;
  }
`;

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  min-width: 232px;
  border-radius: 8px;
  padding: 24px 16px;
  box-shadow: 0 4px 32px 0 rgba(31, 34, 68, 0.2);
  font-family: var(--font-poppins);
  background-color: ${resolveThemeValue(tokens.color.backgrounds.surfaceContrast, 'var(--color-white)')};
  @media ${deviceMaxWidth.phone} {
    width: 100%;
  }
`;

const ListItem = styled.li<{ selected: boolean }>`
  list-style: none;
  width: 100%;
  font-family: var(--font-poppins);
  margin-bottom: 32px;
  cursor: pointer;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  display: flex;
  align-items: center;

  ${resolveInCodebase(
    'fwc',
    css`
      color: black;
    `
  )}

  &:hover {
    span {
      text-decoration: underline;
    }
  }

  span {
    font-weight: ${(props) => (props.selected ? '600' : 'normal')};
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const IconDown = styled.i`
  display: inline-block;
  margin-left: 10px;

  &.up {
    transform: rotate(180deg);
  }
`;

const FlagImg = styled.div`
  margin-right: 8px;
`;

export default DropDown;
