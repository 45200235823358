import { Box } from 'ui/primitives/box';
import tokens from 'ui/design/tokens/tokens';
import { Body } from 'ui/components/primitives/typography/CustomTypography';
import { Language } from 'features/utils/routing';
import { Trans } from '@lingui/react';
import { useSelector } from 'react-redux';
import { settingsSelector } from 'features/redux/selectors/settings';
import { routes } from '~/src/utils/routing';
import LocalizedLink from 'features/routing/LocalizedLink';
import { FlexAlignCenter } from 'ui/primitives/flex';
import { trackSpecialOffer, tryCallDataLayerEvent } from '~/src/utils/dataLayer';
import { ReactNode } from 'react';
import styled from 'styled-components';

type LanguageFWC = Language.en | Language.de | Language.it;

type LinkObj = {
  href: (typeof routes)[keyof typeof routes];
  linkParams: Record<string, string>;
};

type OffersObj = {
  link: Record<LanguageFWC, LinkObj>;
  text: string | ReactNode;
};

export default function SpecialOfferFWC() {
  const settings = useSelector(settingsSelector);
  const language = settings.language as LanguageFWC;
  const offers: OffersObj[] = [
    {
      link: {
        [Language.en]: {
          href: routes.blogDetail,
          linkParams: {
            slug: 'early-booking',
            id: '557',
          },
        },
        [Language.de]: {
          href: routes.blogDetail,
          linkParams: {
            slug: 'fruehbucher',
            id: '558',
          },
        },
        [Language.it]: {
          href: routes.blogDetail,
          linkParams: {
            slug: 'prenotazioni-anticipate',
            id: '559',
          },
        },
      },
      text: (
        <Trans
          id="specialoffer.fwc.title.option1" // switch between option 1 and option 2
          message="With the code SUNNY10, you save 10% on all trips in 2024"
        />
      ),
    },
    {
      link: {
        [Language.en]: {
          href: routes.dynamicSegmentNew,
          linkParams: {
            slug: 'summer-special',
            id: '689',
          },
        },
        [Language.de]: {
          href: routes.dynamicSegmentNew,
          linkParams: {
            slug: 'summer-special',
            id: '688',
          },
        },
        [Language.it]: {
          href: routes.dynamicSegmentNew,
          linkParams: {
            slug: 'summer-special',
            id: '691',
          },
        },
      },
      text: (
        <Trans
          id="specialoffer.fwc.second.option1" // switch between option 1 and option 2
          message="Summer from 69$/night"
        />
      ),
    },
  ];

  const hasOffers = offers.some((it) => !!it.link[settings.language]);

  if (!hasOffers) return null;

  return (
    <Box
      $position="sticky"
      $zIndex={1000}
      $offset={{
        top: '0',
      }}
      $background={tokens.color.border.borderActive}
    >
      <FlexAlignCenter
        $gap={tokens.spacing.spacingM}
        $px={tokens.spacing.spacingL}
        $mdMin={{
          $px: '0px',
          $py: tokens.spacing.spacingS,
        }}
        $maxWidth="1200px"
        $margin="auto"
        $py={tokens.spacing.spacingS}
      >
        {offers.map((offer, index) => {
          const link = offer.link[settings.language];

          if (!link) return null;

          return (
            <>
              {index >= 1 && <Box $height="1em" $background={tokens.color.text.textContrastPrimary} $width="1px" />}
              <FlexAlignCenter
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                $gap={tokens.spacing.spacingS}
              >
                <LocalizedLink
                  href={link.href}
                  linkParams={link.linkParams}
                  onClick={(e) => {
                    tryCallDataLayerEvent(() => {
                      if ('href' in e.currentTarget) {
                        return trackSpecialOffer({
                          headline: 'innerText' in e.target ? (e.currentTarget.innerText as string) : '',
                          link_url: e.currentTarget.href as string,
                          id: offer.link[language].linkParams?.id,
                        });
                      }
                    });
                  }}
                >
                  <Body $size="m" $tDecoration="underline !important" $color={tokens.color.text.textContrastPrimary}>
                    {offer.text}
                  </Body>
                </LocalizedLink>
              </FlexAlignCenter>
            </>
          );
        })}
      </FlexAlignCenter>
    </Box>
  );
}
