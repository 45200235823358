import React, { ReactNode, useRef, useState } from 'react';
import Collapsible from 'react-collapsible';
import styled, { css } from 'styled-components';
import { resolveThemeValue } from '../design/utils';
import tokens from '../design/tokens/tokens';
import { Body } from './primitives/typography/CustomTypography';
import IconArrowDown from '../icons/icon_arrow_down.svg';
import IconArrowUp from '../icons/icon_arrow_up.svg';
import { Box } from '../primitives/box';
import { FlexAlignCenter } from '../primitives/flex';

interface Props {
  icon?: string;
  heading: string | ReactNode;
  children: ReactNode;
  isCollapsed?: boolean;
  autoOpen?: boolean;
  setCollapsedElementId?: (e: any) => void;
  elementId?: string | number | null;
  theme?: 'light' | 'dark';
  summaryWidgetVersion?: boolean;
  arrowContent?: ReactNode;
  className?: string;
}

export default function AccordionFWC({
  icon = '',
  heading,
  children,
  isCollapsed = false,
  autoOpen = false,
  arrowContent,
  setCollapsedElementId,
  elementId = null,
  theme = 'dark',
  summaryWidgetVersion,
  className,
}: Props) {
  const triggerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(isCollapsed);

  const getHeadingColor = () => {
    if (summaryWidgetVersion) {
      return tokens.color.text.textLink;
    }
    if (theme === 'light') {
      return tokens.color.text.textContrastPrimary;
    }

    return tokens.color.text.textPrimary;
  };

  const getWidth = () => {
    if (autoOpen) return '100%';
    return isCollapsed ? '0' : '100%';
  };

  return (
    <CollapsibleWrapper className={className}>
      <Collapsible
        open={isCollapsed}
        trigger={
          <CollapsibleTrigger isCollapsed={isOpen} ref={triggerRef} iconColor={getHeadingColor()}>
            {icon && <i className={icon} />}
            <CollapsibleHeading $size={summaryWidgetVersion ? 'mBold' : 'xlBold'} $color={getHeadingColor()}>
              {heading}
              <FlexAlignCenter $gap={tokens.spacing.spacingS}>
                {arrowContent}
                <IconWrap iconColor={getHeadingColor()}>{isOpen ? <IconArrowUp /> : <IconArrowDown />}</IconWrap>
              </FlexAlignCenter>
            </CollapsibleHeading>
          </CollapsibleTrigger>
        }
        onOpening={() => {
          if (setCollapsedElementId) {
            setCollapsedElementId(elementId);
          }
          triggerRef?.current?.classList?.add('opened');
          setIsOpen(true);
        }}
        onClosing={() => {
          triggerRef?.current?.classList.remove('opened');
          setIsOpen(false);
        }}
        easing="linear"
        transitionTime={300}
      >
        <Box $width={getWidth()} $pt={tokens.spacing.spacingM}>
          {children}
        </Box>
      </Collapsible>
    </CollapsibleWrapper>
  );
}

const CollapsibleWrapper = styled.div`
  width: 100%;
`;

const CollapsibleTrigger = styled.div<{ isCollapsed: boolean; iconColor: string }>`
  cursor: pointer;
  position: relative;
`;

const IconWrap = styled.div<{ iconColor: string }>`
  display: flex;
  cursor: pointer;

  svg {
    margin: auto;
  }

  ${({ iconColor }) =>
    iconColor &&
    css`
      svg path {
        fill: ${resolveThemeValue(iconColor)};
      }
    `}
`;

export const CollapsibleHeading = styled(Body)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;
